body {
  font-family: "Lato", sans-serif;
}

/* pertains to subcomponent opening */

.login {
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  top: 32px;
  right: 80px;
}
.container {
  display: flex;
  flex-direction: column;

  align-items: center;
}
.logoTop {
  margin-left: 486px;
  margin-right: 485px;
  margin-top: 230px;
  margin-bottom: 32px;
  text-align: center;
  padding-top: 40px;
  padding-left: 50px;
  padding-right: 50px;
}

.logoBottom {
  margin-bottom: 230px;
}
/* .opening {
  background-image: url(./DesignAssets/gpc_hero_image@2x.png);
  background-size: cover;
  background-position: center;
} */

#logoImg {
  height: 100px;
}

/* pertain to subcomponent "who" */
#who {
  color: #0e5c89;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 160px;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 5%;
  margin-bottom: 5%;
}

span > p {
  text-align: left;
}

/* pertains to subcomponent Projects */
.projectsFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 80px;
  padding-top: 80px;
}

.blackbackground {
  background-color: #2f2e2e;
}

.projectsImg {
  height: 210px;
  width: 210px;
}

.flexbasis100 {
  flex-basis: 100%;
  margin-bottom: 64px;
}

/* div > span {
  display: block;
  text-align: center;
} */

.projectsFlex > div {
  margin-left: 40.5px;
  margin-right: 40.5px;
  text-align: center;
  color: white;
}

/* pertains to newsletter */

.newsletter {
  color: #0e5c89;
  text-align: center;
  margin-top: 88px;
}
/* pertains to newsletter form */
.classForm {
  margin: auto;
  height: 49px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 90px;
}

.testhere {
  text-align: center;
  margin-top: 45px;
}

input {
  /* width: 2760px; */
  padding-left: 20px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-width: 1px;
  /* height: 47px; */
  margin: 0;
  vertical-align: middle;
  font-size: 14px;
}

input[value="SUBSCRIBE"] {
  height: 100%;
  width: 165px;
  padding: 0;
  border-width: 0;
  background-color: #0e5c89;
  color: white;
  font-weight: 900;
  font-size: 14px;
  vertical-align: middle;
  cursor: pointer;
}

/* pertains to footer */

.footerBackground {
  background-color: #e8e8e8;
  /* padding-left: 696px;
  padding-right: 695px; */
  padding-top: 29px;
  padding-bottom: 29px;
  text-align: center;
}

/* pertains to login signup */
.backgroundPic {
  /* background-image: url(./DesignAssets/gpc_loginBg@2x.png); */
  /* background-size: cover;
  background-position: center; */
  background-repeat: no-repeat;

  position: fixed;
  top: 0;
  left: 0;

  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
}

/* text input */

.inputStyle {
  background-color: rgba(196, 196, 196, 0.3);
  padding: 0px 0px 0px 70px;
  border-width: 0px;
  font-size: 14px;
  font-weight: bold;
  border-color: transparent;
  color: #d1d1d1;
}

.inputStyle::placeholder {
  color: #d1d1d1;
}

.inputStyle:focus {
  color: white;
}

/* pertains to LoginSign */
.SignLogin {
  padding-top: 100px;
  /* padding-bottom: 236px; */
  text-align: center;
  /* margin-bottom: 236px; */
  z-index: 2;
}
.logoSize {
  width: 200px;
}

.topLeftLogo {
  position: absolute;
  top: 32px;
  left: 80px;
  z-index: 3;
}

/* Global */
h2 {
  font-weight: "demi bold";
  font-size: 32px;
  padding: 0;
  margin: 0;
}
h3 {
  font-weight: medium;
  font-size: 24px;
}

h5 {
  font-weight: "medium bold";
  font-size: 20px;
}

p {
  font-weight: medium;
  font-size: 18px;
  color: #1b1e1f;
}

.picSize {
  height: 6%;
  width: 6%;
}

@media (max-width: 767px) {
  .picSize {
    height: 20%;
    width: 20%;
  }
}
