.PatientInfoParent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
}

.PatientInfoHeader {
  font-weight: bold;
}

.PatientInfoSubsection {
  background-color: white;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  color: black;
}

.PatientInfo {
  background-color: gray;
  text-align: center;
  border-radius: 5px;
  flex-grow: 1;
}

.PatientInfoTitle {
  background-color: #15794f;
  color: white;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  border-color: white;
  border-style: solid;
  border-width: 2px;
  padding: 10px;
  /* margin: 10px; */
}

@media (max-width: 767px) {
  .PatientInfoTitle {
    font-size: 20px;
  }
  .goBackBtn {
  }
}

.PCFOuter {
}

.ReviewParent {
  background-color: #15794f;
  border-radius: 5px;
  padding: 5px;
}

.ReviewGrid {
  background-color: #d1f7e7;
  padding: 5px;
  margin-left: 2px;
  margin-right: 2px;
  width: 75%;
}

.childAnswers {
  background-color: blue;
}

.highlightsParent {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.highlightChild {
  color: black;
  font-weight: 700;
  border-radius: 5px;
  padding: 2px;
  margin: 1px;
  text-align: center;
}

.smallBeginReportBtn {
  max-width: 50px;
}

.ViewReportBtn {
  margin: 5px;
}
.toggleBillingBtn {
  margin: 5px;
  align-items: center;
  text-align: center;
  vertical-align: center;
}

.clickToApprove {
  color: #6aa38b;
  background-color: white;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  vertical-align: center;
  text-align: center;
}
.clickToApprove:hover {
  background-color: #15794f;
  cursor: pointer;
}

.clickToUnapprove {
  background-color: white;
  color: #15794f;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  vertical-align: center;
  text-align: center;
}

.clickToUnapprove:hover {
  /* color: #6aa38b; */
  color: red;
  cursor: pointer;
}
