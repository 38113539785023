.formParent {
  background-color: #188a5a;
  border-radius: 5px;
  padding: 5px;
  /* display: flex; */
  justify-content: center;
}

.ReviewBtn {
  padding: 1%;
  margin: 1%;
  display: flex;
  justify-content: center;
  background: #d1f7e7;
  border-radius: 5px;
}

.ReviewBtn div {
  margin-left: 5%;
  margin-right: 5%;
}

.QuestionTitle {
  background-color: #d1f7e7;

  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  color: #15794f;
  font-weight: 700;
}

.option {
  margin: 10px;
}

.optionParent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.overallQuestion {
  margin: 10px;
  flex-basis: 30%;
  text-align: center;
}

.InputError {
  text-align: left;
  color: red;
  background: white;
  padding: 5px;
  font-weight: 400;
  max-width: 75px;
  margin: 5px;
  border-radius: 5px;
}

.errorOnSubmit {
  color: red;
}

@media (max-width: 550px) {
  .overallQuestion {
    flex-basis: 80%;
  }
}

.renderGroupChild {
  background-color: #d1f7e7;
  border-radius: 5px;
  flex-basis: 5%;
  flex-wrap: wrap;
  margin: 50px;
}

.renderGroupParent {
  margin: 10px;
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.datetimefield {
  background-color: #d1f7e7;
  padding: 1px;
  margin: auto;
  width: 90%;
  align-items: center;
  border-radius: 5px;
  /* border-color: #15794f;
  border-style: solid;
  border-width: ; */
}

.ReviewSubmissionParent {
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  flex-direction: row;
  margin: 10px;
}

.ReviewTitle {
  text-align: center;
  background-color: #d1f7e7;
  border-radius: 5px;
  margin: 5px;
  font-weight: bold;
  color: #15794f;
  padding: 5px;
  font-size: 20px;
}

.ReviewSection {
  flex-basis: 30%;
  background-color: #d1f7e7;
  margin: 2px;

  border-radius: 5px;
}

.ReviewGrid {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 5px;
  text-align: left;
  font-weight: bold;
}

.ReviewGridAnswer {
  font-weight: 200;
}

.insuranceReview {
  margin-right: 20px;
}
