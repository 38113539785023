.pharmacyBtn {
  margin: 100px;
  background-color: green;
  color: black;
}

.liveCircle {
  height: 15px;
  width: 15px;
  background-color: #34eb52;
  border-radius: 50%;
  display: inline-block;
  border-width: 1px;
  border-color: black;
  border-style: solid;
}

.offlineCircle {
  height: 15px;
  width: 15px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  border-color: black;
  border-width: 1px;
  border-style: solid;
}

.live {
  color: #34eb52;
}
.offline {
  color: red;
}
