.resultsGrid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  background-color: #ccd1d1;
  border-radius: 5px;
  padding: 50px;
}
.exempt {
  color: white;
  background-color: red;
  padding: 2px;
  margin: 5px;
  text-align: center;
  border-radius: 5px;
}

.resultsGridHeader {
  font-weight: bold;
  font-size: 30px;
  color: #15794f;
  padding: 2px;
  margin: 5px;
  border: solid 2px #15794f;
  border-radius: 5px;
  text-align: center;
  background-color: white;
}

.notExempt {
  color: #15794f;
  padding: 2px;
  margin: 5px;
  text-align: center;
  border-radius: 5px;
}

.editStatusBtn {
  color: #15794f;
}

.editStatusBtn:hover {
  color: white;
  background-color: #15794f;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 10px;
}

.MuiDialog-paperWidthLg {
  width: 75%;
}

.title {
  color: white;
  background-color: #15794f;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  padding: 2%;
  margin-bottom: 20px;

  font-weight: bold;
  font-size: 30px;
}

.searchbox {
  width: 80%;
  text-align: center;
}

.parent {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background-color: #d1f7e7;
  border-radius: 5px;
  padding: 20px;
  padding-bottom: 200px;
}

.dialoguePromptText {
  color: #15794f;
  background-color: #d1f7e7;
  border-radius: 5px;
  padding: 20px;
  font-size: 25px;
}

.dialogError {
  color: red;
  font-weight: bold;
}
