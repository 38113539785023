.App {
  display: flex;
  font-family: Arial, sans-serif;
}

.sidebar {
  width: 200px;
  height: 100vh;
  background-color: #f0f0f0;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.content {
  flex-grow: 1;
  padding: 20px;
}

.tab {
  display: block;
  background-color: #fff;
  color: #333;
  padding: 15px;
  border: none;
  border-radius: 5px;
  text-align: left;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.tab.active {
  background-color: hsl(143, 49%, 37%);
  color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.tab:hover {
  background-color: #0056b3;
  color: #fff;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 10px;
  text-align: left;
}
