.pageWidth {
  margin: 5px;
  padding: 5px;
  max-width: 95%;
}

@media (max-width: 750px) {
  .inputReview {
    font-size: 10px;
  }
  .inputHeaderReview {
    font-size: 15px;
  }

  .pageWidth {
    margin: 0px;
    padding: 0px;
    max-width: 100%;
  }
}

.patientInfo {
  display: grid;
  grid-template-columns: auto auto;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  margin: 20px;
}

.patientInfoHeader {
  font-weight: bold;
}

.patientInfoParent {
  display: flex;
  flex-direction: column;
  margin: 5px;
  justify-content: center;
  align-content: center;
}

.buttonLayout {
  display: grid;
  grid-template-columns: auto auto;
}
