/* styles.css */

.generalSection {
    display: "flex";
    flex-direction: "column";
    align-items: "center";
    background-color: rgb(227, 227, 227);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
    width: 80%;
  }
  
  /* Mobile styles */
  @media screen and (max-width: 600px) {
    .generalSection {
        /* display: "flex";
        flex-direction: "column";
        align-items: "center"; */
        width: 100%;
        margin: 10px 0;
        padding: 10px;
    }
    .modalContainer {
        display:'flex';
    }
  }
  