/* .title {
  flex-grow: 1;
  font-size: 100%;
} */

/* .connectionStatus {
  font-size: 10%;
} */

.icon {
  max-width: 6vw;
  max-height: 6vw;
  min-height: 30px;
  min-width: 60px;
  padding: 5px;
  margin: 5px;
  background-color: white;
  border-radius: 5px;
}
