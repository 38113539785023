.insuranceInfoParentUnclicked {
  padding: 10px;
}

.insuranceInfoParentClicked {
  border: 5px red solid;
  color: red;
}

.inscheader {
  font-weight: 800;
  display: inline;
}

.inscvalue {
  display: inline;
}

.subcontainer {
  text-align: left;
}

.policyRemovalTitle {
  font-weight: 700;
  padding: 5px;
  border: 1px #15794f solid;
  margin: 4px;
  border-radius: 5px;
  background-color: #d1f7e7;
}

.policyRemovalParent {
  color: #15794f;
  background-color: #d1f7e7;
  padding: 3px;
  border-radius: 5px;
}
