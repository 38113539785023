@media screen and (max-width: 1000px) {
  /* .FlexSearchCardParent {
    display: inline-block;
  } */
}

.card {
  flex: 1;
  margin: 5px;
  padding: 5px;
  align-items: center;
}

.FlexSearchCardParent {
  display: flex;
  flex-direction: row;
  min-height: 30px;
  flex-wrap: wrap;
  padding: 10px;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}

.ChildFlexSearchRow {
  flex-grow: 3;
  flex-shrink: 0;
  flex-basis: 65%;
  flex-basis: 500px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
}

.ChildFlexButtons {
  height: 80px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 20%;
  flex-basis: 100px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
}
.ChildOfChildFlexButtons {
  width: 100%;
  height: 100%;
  grid-row: 2;
}

.FlexTitle {
  height: 80px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 15%;
  flex-basis: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
