.pageWidth {
  margin: 5px;
  padding: 5px;
  max-width: 95%;
}

@media (max-width: 750px) {
  .inputReview {
    font-size: 10px;
  }
  .inputHeaderReview {
    font-size: 15px;
  }

  .pageWidth {
    margin: 0px;
    padding: 0px;
    max-width: 100%;
  }
}

.patientInfo {
  display: grid;
  grid-template-columns: auto auto;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  margin: 20px;
}

.patientInfoHeader {
  font-weight: bold;
}

.patientInfoParent {
  display: flex;
  flex-direction: column;
  margin: 5px;
  justify-content: center;
  align-content: center;
}

.RowHighlightsKeyParent {
  border: 1px solid #15794f;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
}

.RowHighlightsKeyParent > div {
  padding: 5px;
  font-size: 12px;
}

.highlightsKeyTitle {
  text-align: center;
  font-weight: bold;
}
.lowRiskSy {
  background-color: #e8f797;
}
.highRiskSy {
  background-color: #f5a6d6;
}
.outsidePharm {
  background-color: #51D6FF;
}
