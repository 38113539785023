.flexParent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #d1f7e7;
  border-radius: 10px;
  margin: 20px;
}

.flexChild {
  display: block;
  margin: 20;
  color: "green";
  flex-basis: 30%;
  margin: 10px;
}

.flexFill {
  flex-grow: 1;
  color: red;
}

.rowHeader {
  font-weight: bold;
}

.paragraph {
  margin: 40px;
  font-size: 20px;
  text-align: left;
}

.parent {
  align-content: center;
}

.fileInput {
  margin: 20px;
}

.alerts {
  color: red;
}
