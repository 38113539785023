.nurseRosterParent {
  display: flex;
  justify-content: center;
}
.nurseRosterTable {
  width: 70%;
  font-size: 20px;
}

tr:hover {
  background-color: #ddd;
}

.nurseRosterTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}

.download-button {
  color: #15794f;
  height: 58px;
  width: 30px;
  margin: 0px 10px;
  border-radius: 5px;
  padding: 3px;
}

.download-button:hover {
  color: #439372;
}

.nurse-roster-container {
  margin: 30px;
  padding: 20px;
  display: flex;
}

.date-label{
  padding-left: 130px;
}
