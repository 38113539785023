.initialProspectParent {
  padding: 10px;
  margin: 30px;
}
.pickGrid {
  display: grid;
  grid-template-columns: auto auto auto;
  color: #15794f;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  vertical-align: center;
}

.pickGridRow:hover {
  cursor: pointer;
  background-color: #15794f;
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  vertical-align: center;
}

.backArrow {
  color: #15794f;
  font-size: 25px;
  border: solid 5px #15794f;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
}

.backArrow:hover {
  cursor: pointer;
  color: white;
  background-color: #15794f;
}

.NarrativeGrid {
  display: grid;
  grid-template-columns: auto auto auto;
}

.narrativeGridHeader {
  font-weight: bold;
  font-size: 20px;
  color: #15794f;
  margin-bottom: 10px;
  margin-top: 10px;
}

.narrativeGridBody {
  margin-top: 5px;
  margin-bottom: 5px;
}

.narrativeGridAddButton {
  color: #15794f;
  font-size: 40px;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
}
.narrativeGridAddButton:hover {
  cursor: pointer;
  background-color: #15794f;
  color: white;

  text-align: center;
  vertical-align: center;
}
