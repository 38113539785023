.PCFReviewHeader {
  background-color: gray;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 30px;
}
.PCFReviewHeaderKey {
  color: white;
  font-weight: bold;
}
.PCFReviewHeaderValue {
  color: white;
}

.horizontalNav {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
}
/* .horizontalNav > div {
  background-color: #15794f;
  color: white;
  text-align: center;

  font-size: 20px;
  padding: 5px;
  border-radius: 5px;
  margin: 6px;
} */

.horizontalNavNotSelected:hover {
  cursor: pointer;
  color: #15794f;
  background-color: white;
  border: solid 1px #15794f;
  text-align: center;
  font-size: 17px;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
}

.horizontalNavSelected {
  color: #15794f;
  background-color: white;
  border: solid 1px #15794f;
  text-align: center;

  font-size: 17px;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
}

.horizontalNavNotSelected {
  background-color: #15794f;
  color: white;
  text-align: center;
  font-size: 17px;
  padding: 5px;
  border-radius: 5px;
  border: solid 1px #15794f;
  margin: 5px;
}

.date {
  font-size: 14px;
}

.onError {
  background-color: red;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
}

.WeekGridParent {
  display: grid;
  grid-template-columns: auto auto auto;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.WeekGridArrows {
  cursor: pointer;
  font-size: 30px;
  color: #15794f;
}

.liveconnection {
  background-color: greenyellow;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: black solid 1px;
}
.disconnection {
  background-color: red;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: black solid 1px;
}

.liveConnGrid {
  display: grid;
  grid-template-columns: auto auto;
  background-color: rgb(192, 186, 186);

  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}

.modifiedScheduleDetails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 30%;
  justify-content: flex-start;
  align-content: center;
  margin: 20px;
  background-color: rgb(224, 215, 215);
  border-radius: 5px;
}

.modifiedScheduleDetailsHeader {
  font-weight: bold;
}

.modifiedScheduleDetailsSection {
  padding: 10px;
}

.topOfPageLayout {
  display: grid;
  grid-template-columns: 20% auto;
}

.csv-link {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.link-button {
  background-color: #15794f;
  border: 0;
  border-radius: 4px;
  margin: 5px 10px;
  text-align: center;
  height: 30px;
  width: 237px;
  padding: 10px;
}
.link-button:hover {
  background-color: #439372;
}

.print-button {
  color: #15794f;
  height: 35px;
  width: 35px;
}

.print-button:hover {
  color: #439372;
}

.hidden-component {
  display: none;
}

svg {
  vertical-align: middle;
}
