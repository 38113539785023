.reportRenderParent {
  border-color: transparent;
}
.reportRenderParent tr {
}
.reportRenderParent tr:hover {
  background-color: rgb(219, 216, 216);
}

.header {
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

.buttonRow {
  margin: 10px;
}
