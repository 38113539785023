.FilterRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  border: solid 1px #15794f;
  border-radius: 5px;
  color: #15794f;
}

.FilterRow > div {
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  align-self: center;
}
.FilterTitle {
  align-self: center;
  font-weight: bold;
}

.resetBTN {
  max-width: 5%;
}
