.InfoWindowContainer {
  border-radius: 10px;
}

.name {
  color: black;
  font-size: 20px;
}

.address {
  color: black;
  font-size: 15px;
}

.nurseMarker {
  font-size: 40px;
  color: #15794f;
}

.nurseMarkerSelected {
  font-size: 50px;
  color: red;
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.PatientMarker {
  font-size: 30px;
  color: #1978c8;
}

.PatientMarkerSelected {
  font-size: 50px;
  color: red;
}

.nurseHover {
  font-size: 20px;
  width: 400px;
  color: black;
  background-color: white;
  border-color: black;
  padding: 10px;
  border-radius: 10px;
  border-color: #15794f;
  border-width: 4px;
  border-style: solid;
  position: absolute;
  left: -100px;
  top: 50px;
  z-index: 10;
}

.patientHover {
  font-size: 20px;
  width: 400px;
  color: black;
  background-color: white;
  border-color: black;
  padding: 10px;
  border-radius: 10px;
  border-color: blue;
  border-width: 4px;
  border-style: solid;
  position: absolute;
  left: -100px;
  top: 50px;
  z-index: 10;
}

.top {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.MapParent {
  display: inline-flex;
  flex-flow: row wrap;

  height: 100%;
}
.map {
  flex: 3;
  height: 100%;
}
.MapStats {
  flex: 0 0 100%; /* Let it fill the entire space horizontally */

  background-color: #15794f;
}
.MapControlColumn {
  background-color: #d1f7e7;
  height: 100%;
  flex: 1;
}

.arrangeControlSwitches {
  text-align: center;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 5px;
  background-color: white;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}

.switchTitle {
  background-color: #15794f;
  color: white;
  border-radius: 5px;
  vertical-align: center;
  padding: 5px;
  margin: 5px;
}

.totalCount {
  background-color: #15794f;
  color: white;
  border-radius: 5px;
  vertical-align: center;
  padding: 5px;
  margin: 5px;
}

.dataDisplaySubjectParent {
  max-height: 1000px;
  overflow-y: scroll;
}

.dataDisplaySubjectParentHeader {
  padding: 10px;
  border: 3px #15794f solid;
  background-color: white;
  margin: 5px;
  border-radius: 5px;
}

.patientsHeader {
  text-align: center;
  font-weight: bold;
  color: white;
  background-color: #15794f;
  padding: 5px;
  border-radius: 10px;
}

.listedPatient {
  background-color: #c0c4c1;
  border-radius: 10px;
  padding: 10px;
  margin: 2px;
}

.lineHeader {
  font-weight: bold;
}

.line {
  display: grid;
  grid-template-columns: auto auto;
}

.buttongrid {
  display: grid;
  grid-template-columns: auto auto;
}

.projectNote {
  background-color: white;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}

.StateStatsParent {
  margin: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}
.StateStatsHeader {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  font-weight: bold;
  color: #15794f;
}
.StateStatsBody {
  margin: 5px;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  color: #15794f;
}

.StateStatsTitle {
  color: white;
  height: 100%;
  width: 100%;

  font-size: 30px;
  font-weight: bold;
  /* border: white 2px solid;
  border-radius: 5px; */
}

.flexGroup {
  align-self: center;
}

.NavIcons {
  color: #15794f;
  font-size: 30px;
  background-color: white;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.NavIcons > span {
  margin: 60px;
  padding: 10px;
}

.NavIcons > span:hover {
  cursor: pointer;
  margin: 60px;
  color: white;
  background-color: #15794f;
  border-radius: 50%;
  padding: 10px;
}
