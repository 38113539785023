.VOSelectPatParent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 50%;
  justify-content: flex-start;
  align-content: flex-start;
  margin: 5px;
}

.VOSelectInstructions {
  text-decoration: underline;
  margin: 20px;
}

.searchPatient {
  min-width: 200px;
  margin: 3%;
  margin-top: 50px;
}

.VOTitle {
  margin: 20px;
  font-weight: bold;
  background-color: #15794f;
  color: white;
  text-align: center;
  vertical-align: center;
  padding: 10px;
  border-radius: 5px;
}

.newPatientBtn {
  color: #15794f;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #15794f;
  margin: 10px;
  text-align: center;
  max-width: 40%;
}

.patientInfo {
  display: flex;
  flex-direction: row;
  flex-basis: 33%;
  flex-wrap: wrap;
  background-color: aliceblue;
  border-radius: 5px;
}

.patientInfo > div {
  margin: 5px;
}

.formikFormParent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 5px;
  /* border: #15794f solid 1px; */
  border-radius: 5px;
}

.formikFormParent > div {
  margin: 10px;
  vertical-align: middle;
}

.formError {
  background-color: red;
  color: white;
  margin: 5px;
  padding: 0px;
  text-align: center;
  width: 50%;
  border-radius: 5px;
}

.formikSubmit {
  padding: 15px;
  background-color: #15794f;
  color: white;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 100px;
}

.dateStyle {
  margin: 5px;
}

.openDialogueBtn {
  color: #15794f;
  background-color: white;
  border-radius: 5px;
  border: 2px solid #15794f;
  text-align: center;
  padding: 10px;
}

.openDialogueError {
  color: red;
  background-color: white;
  border-radius: 5px;
  border: 2px solid red;
  text-align: center;
  padding: 10px;
}

.pickOneParent {
  border: 2px solid red;
  padding: 5px;
  border-radius: 5px;
}

.patientMapper {
  margin: 5px;
  border: 1px solid #15794f;
  padding: 5px;
  border-radius: 5px;
  color: #15794f;
}

.searchbtn {
  background-color: #15794f;
  color: white;
  padding: 10px;
  margin: 15px;
  text-align: center;
  border-radius: 5px;
}
