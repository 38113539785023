.WeekList {
  margin: 20px;
  /* border-color: #15794f;
  border-style: solid; */
  border-radius: 5px;
  padding: 20px;
  width: 90%;
  text-align: left;
  background-color: #ccd1d1;
}
.WeekListTable td {
}

.WeekListTable th {
  background-color: #15794f;
  color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

/* tr:hover {
  background-color: red;
  padding: 10px;
  /* border-color: #15794f;
  border-style: solid;
  border-width: 2px;
   */

.WeekListTable {
  /* display: grid; */
  /* grid-template-columns: auto auto; */
  /* grid-row-gap: 20px;
  grid-column-gap: 5px; */
  width: 75%;
  border-spacing: 10px;
}

.entireRow {
  margin: 5px;
  background-color: white;
  padding: 10px;

  border-radius: 15px;
  width: 50%;
  text-align: center;
  color: #15794f;
  font-weight: 400;
}

.weeklytotalstable {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

.row {
  text-align: left;
  display: grid;
  grid-template-columns: auto auto auto;
}

.row:hover {
  /* background-color: red; */
  border-color: #15794f;
  border-style: solid;
  border-width: 3px;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
}

.miles {
  text-align: left;
}

.gridHeader {
  font-weight: bold;
  color: #15794f;
  padding: 2px;
  margin: 5px;
  border: solid 2px #15794f;
  border-radius: 5px;
}

.hoverRow:hover {
  color: #15794f;

  cursor: pointer;
}
