.menuParent {
  display: flex;
  /* grid-template-columns: auto auto auto; */
  flex-direction: row;
  flex-wrap: wrap;

  /* grid-gap: 10px; */
  height: 100%;
  /* margin: auto; */
  background-color: #edf7f0;
  justify-content: center;
  align-content: flex-start;
}

.menuChild {
  padding: 10px;
  flex-basis: 30%;
}
@media screen and (max-width: 600px) {
  .menuParent {
    display: inline;
  }
}
