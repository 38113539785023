/* AdminPanel.css */
.admin-panel {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.section {
    border: 1px solid #e0e0e0;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 5px;
}

.section-header {
    font-size: 1.5em;
    border-bottom: 1px solid #d0d0d0;
    margin-bottom: 10px;
}

.input-group {
    display: flex;
    align-items: center;
}

.input-group input {
    margin-right: 10px;
}